<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>{{ $t(header) }}
          <b-link :to="{ name: 'Options' }">
            <b-icon-arrow-return-left/>
          </b-link>
        </h1>
        <b-breadcrumb :items="br_items"/>
      </b-col>
    </b-row>
    <b-row class="header_row">
      <b-input-group>
        <b-input v-model="filter"/>
        <b-input-group-append>
          <b-checkbox v-model="show_deleted" button button-variant="outline-danger" v-b-tooltip="$t('Show deleted')">
            <b-icon-trash-fill/>
          </b-checkbox>
          <b-button variant="outline-primary" @click="show_modal = true">
            <b-icon-plus/>
          </b-button>
          <b-button variant="info" v-if="to_collapse.length >= 2" v-b-tooltip="'Collapse'" @click="show_collapse=true">
            <b-icon-collection/>
            <b-badge variant="info">
              {{ to_collapse.length }}
            </b-badge>
          </b-button>
          <b-button variant="outline-info" v-b-tooltip="$t('Import')" @click="import_modal=true">
            <b-icon-upload/>
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <b-table :items="items" :fields="fields" :per-page="perPage"
               :current-page="currentPage">
        <template v-slot:cell(collapse)="item">
          <b-checkbox v-model="to_collapse" :value="item.item"/>
        </template>
        <template v-slot:cell(actions)="item">
          <b-button size="lg"
                    variant="outline-warning"
                    @click="show_edit(item.item.uuid)"
          >
            <b-icon-pen/>
          </b-button>
          <b-button
              v-if="!item.item.deleted"
              variant="outline-danger"
              size="lg"
              @click="delete_item(item.item.uuid)"
          >
            <b-icon-trash/>
          </b-button>

        </template>
      </b-table>
      <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
      />
    </b-row>
    <b-modal v-model="show_modal" :title="$t(`Create ${type}`)" @ok="create_info()">
      <b-form>
        <b-form-group :label="$t('Name')">
          <b-input v-model="new_data.name"/>
        </b-form-group>
        <b-form-group label="Email" v-if="['person', 'operator'].indexOf(type) >= 0">
          <b-input v-model="new_data.email"/>
        </b-form-group>
        <b-form-group :label="$t('Description')">
          <b-input v-model="new_data.description"/>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal v-model="edit_modal" :title="$t(`Update ${type}`)" @ok="update_info()">
      <b-form>
        <b-form-group :label="$t('Name')">
          <b-input v-model="edit_data.name"/>
        </b-form-group>
        <b-form-group label="Email" v-if="['person', 'operator'].indexOf(type) >= 0">
          <b-input v-model="edit_data.email"/>
        </b-form-group>
        <b-form-group :label="$t('Description')">
          <b-input v-model="edit_data.description"/>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal v-model="import_modal" :title="$t('Import')" @ok="import_data()">
      <b-form-file type="file" v-model="file_model"/>
    </b-modal>
    <b-modal v-model="show_collapse" :title="$t('Collapse cells')" @cancel="to_collapse=[]" @ok="collapse()">
      <p>{{ $t('Use as real') }}</p>
      <b-form-row title="Title">
        <b-list-group>
          <b-list-group-item v-for="it in to_collapse" :key="it.uuid" class="select-info">
            <b-form-radio v-model="collapse_as_real" :value="it.pk"/>
            {{ it.name }}
          </b-list-group-item>
        </b-list-group>
      </b-form-row>
    </b-modal>
  </b-container>
</template>

<script>
import {merge} from "lodash-es";
import {SERVER} from '../constants';

const types = {
  person: "Persons",
  cell: "Cells",
  line: "Lines",
  operator: "Operators",
};
export default {
  name: "PersonsList",
  props: {
    type: {
      type: String,
      default: "person",
      required: false,
      validator: (s) => Object.keys(types).indexOf(s) > -1,
    },
    uuid: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      to_collapse: [],
      show_collapse: false,
      collapse_as_real: null,
      currentPage: 1,
      perPage: 10,
      filter: "",
      file_model: null,
      show_deleted: false,
      show_modal: false,
      edit_modal: false,
      import_modal: false,
      edit_uuid: "",
      edit_data: {
        name: null,
        email: null,
        description: null,
      },
      new_data: {
        name: "",
        email: null,
        description: "",
      },
    };
  },
  mounted() {
    this.$store.dispatch("load_data", ['additional_info']);
    this.$watch(
        () => this.show_modal,
        () => {
          this.new_data.name = "";
          this.new_data.description = "";
        }
    );
  },
  computed: {
    br_items() {
      return [
        {text: this.$t('Home'), to: {name: 'Home'}},
        {text: this.$t('Settings'), to: {name: "Options"}},
        {text: this.header, active: true}
      ]
    },
    items() {
      let ret = this.$store.getters[this.type];
      if (!this.show_deleted) {
        ret = ret.filter(o => !o.deleted)
      }
      if (this.filter) {
        const regexp = new RegExp(this.filter, "i");
        ret = ret.filter((o) => regexp.test(o.name + o.description || ""));
      }
      return ret.map((v) =>
          merge(v, {_cellVariants: {deleted: v.deleted ? "danger" : null}})
      );
    },
    header() {
      return this.$t(types[this.type]);
    },
    fields() {
      let ret = [
        {label: '#', key: 'collapse'},
        {label: this.$t("Name"), key: "name", sortable: true},
        {key: "description", label: this.$t('Description'), sortable: true},
        // {key: 'deleted', sortable: true, formatter: value => value ? '✗' : ''},

      ]
      if (['person', 'operator'].indexOf(this.type) >= 0) {
        ret.push(
            {key: 'email', label: 'EMail', sortable: true}
        )
      }
      ret.push(
          {key: "actions", label: this.$t('Actions'), tdClass: 'actions_cell'}
      )
      return ret
    },
  },
  methods: {
    import_data() {
      let data = new FormData();
      data.append(
        'file', this.file_model
      );
      fetch(`${SERVER}/api/v2/import/${this.type}/`, {
        method: 'POST',
        body: data
      }).then(
          () => {
            this.import_modal = false;
            this.$store.dispatch('load_data', ['additional_info'])
          }
      )
    },
    collapse() {
      const to = this.collapse_as_real;
      const send_data = {
        to: to,
        who: this.to_collapse.map(i => i.pk).filter(i => i != to)
      };
      this.$store.dispatch('post', {
        url: 'api/v2/collapse/',
        data: send_data
      }).then(() => {
        this.$store.dispatch('load_data', ['additional_info']);
      })
    },
    delete_item(uuid, type = "additional_info") {
      const obj = this.items.find(o => o.uuid == uuid)
      this.$bvModal.msgBoxConfirm(
          this.$t('delete_msg', {name: obj.name})).then(val => {
        if (val) {
          this.$store.dispatch("update_item", {
            type: type,
            id: uuid,
            data: {
              deleted: new Date(),
            },
          });
        }
      })

    },
    show_edit(uuid) {
      const item = this.items.find((o) => o.uuid == uuid);
      if (item) {
        this.edit_modal = true;
        this.edit_uuid = uuid;
        this.edit_data.description = item.description;
        this.edit_data.name = item.name;
        this.edit_data.email = item.email;
      }
    },
    update_info() {
      this.$store
      .dispatch("update_item", {
        type: "additional_info",
        id: this.edit_uuid,
        data: this.edit_data,
      })
      .then(() => {
        this.edit_data.name = "";
        this.edit_data.email = "";
        this.edit_data.description = "";
      });
    },
    create_info() {
      this.$store.dispatch("add_item", {
        type: "additional_info",
        data: {
          ...this.new_data,
          type: this.type,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.actions_cell {

  button.btn {
    margin-right: 20px
  }

}

.select-info {
  .custom-control.custom-radio {
    display: inline-block;
  }
}
</style>
