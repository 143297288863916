<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>{{repr}}
          <b-link :to="{ name: 'Options' }">
            <b-icon-arrow-return-left/>
          </b-link>
        </h1>
        <b-breadcrumb :items="br_items"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="dep-filter">
        <b-form-row label="Departament">
          <b-input-group>
            <b-form-select
                :options="deparatament_list"
                v-model="deparatament_uuid"
                value-field="uuid"
                text-field="name"
            />
            <b-button-group>
              <b-button
                  variant="outline-success"
                  @click="show_create('departament')"
              >
                <b-icon-plus
                />
              </b-button>

              <b-button v-if='current_dep && !current_dep.deleted' variant="outline-danger"
                        v-b-tooltip="$t('Delete departament')"
                        @click="delete_departament()"
              >
                <b-icon-trash/>
              </b-button>
              <b-button
                  v-if="deparatament_uuid"
                  variant="outline-warning"
                  @click="show_edit(deparatament_uuid, 'departament')"
              >
                <b-icon-brush
                />
              </b-button>
            </b-button-group>
          </b-input-group>
        </b-form-row>
        <b-form-row label="Search">
          <b-input-group>
            <b-input v-model="filter" :placeholder="$t('Search')"/>
            <b-input-group-append>
              <b-button-group>
                <b-checkbox v-model='show_deleted' button button-variant="outline-danger">
                  <b-icon-trash-fill/>
                </b-checkbox>
                <b-button variant="outline-primary" @click="show_create()">
                  <b-icon-plus/>
                </b-button>

                <b-button variant="info" v-if="to_collapse.length >= 2" v-b-tooltip="'Collapse'"
                          @click="show_collapse=true">
                  <b-icon-collection/>
                  <b-badge variant="info">
                    {{to_collapse.length}}
                  </b-badge>
                </b-button>

              </b-button-group>
            </b-input-group-append>
          </b-input-group>
        </b-form-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table v-if='!!items.length' :items="items" :fields="fields">
          <template v-slot:cell(collapse)="item">
            <b-checkbox v-model="to_collapse" :value="item.item"/>
          </template>

          <template v-slot:cell(actions)="item">
            <b-button
                size="lg"
                variant="outline-warning"
                @click="show_edit(item.item.uuid)"
            >
              <b-icon-pen/>
            </b-button>
            <b-button
                size="lg"
                v-if="!item.item.deleted"
                variant="outline-danger"
                @click="delete_item(item.item.uuid)"
            >
              <b-icon-trash/>
            </b-button>

          </template>
        </b-table>
        <h2 v-else>Nothing found</h2>
      </b-col>
    </b-row>
    <b-modal v-model="show_modal" :title="`Create ${current_action}`" @ok="create_info()">
      <b-form>
        <b-form-group label="Name">
          <b-input v-model="new_data.name"/>
        </b-form-group>
        <b-form-group label="Description">
          <b-input v-model="new_data.description"/>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal v-model="edit_modal" :title="`Update ${current_action}`" @ok="update_info()">
      <b-form>
        <b-form-group label="Name">
          <b-input v-model="edit_data.name"/>
        </b-form-group>
        <b-form-group label="Description">
          <b-input v-model="edit_data.description"/>
        </b-form-group>
        <b-form-group v-if='action_type != "departament"' label="Departament">
          <b-select :options="deparatament_list" v-model="edit_data.departament_uuid" value-field="uuid"
                    text-field="name"/>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal v-model="show_collapse" :title="$t('Collapse cells')" @cancel="to_collapse=[]" @ok="collapse()">
      <p>{{$t('Use as real')}}</p>
      <b-form-row title="Title">
        <b-list-group>
          <b-list-group-item v-for="it in to_collapse" :key="it.uuid" class="select-info">
            <b-form-radio v-model="collapse_as_real" :value="it.pk"/>
            {{it.name}}
          </b-list-group-item>
        </b-list-group>
      </b-form-row>
    </b-modal>
  </b-container>
</template>

<script>
  import PersonsListVue from "./PersonsList.vue";
  import {merge} from "lodash-es";

  export default {
    name: "DepartamentCells",
    mixins: [PersonsListVue],
    props: {
      type: {
        default: "cell",
      },
    },
    data() {
      return {
        show_deleted: false,
        deparatament_uuid: null,
        action_type: "additional_type",
      };
    },
    mounted() {
      this.$store.dispatch("load_data", ['departament']);
    },
    computed: {
      current_action() {
        let ret = this.action_type;
        if (ret == 'additional_info') {
          ret = this.type;
        }
        return ret;
      },
      repr() {
        return this.type == 'cell' ? this.$t('Cells') : this.$t('Lines')
      },
      current_dep() {
        return this.deparatament_uuid ? this.$store.getters.departament.find(d => d.uuid == this.deparatament_uuid) : null
      },
      items() {
        let ret = this.$store.getters[this.type].filter(
          (c) => c.departament_uuid == this.deparatament_uuid
        );
        if (!this.show_deleted) {
          ret = ret.filter(o => !o.deleted)
        }
        if (this.filter) {
          const regexp = new RegExp(this.filter, "i");
          ret = ret.filter((o) => regexp.test(o.name + o.description || ""));
        }
        return ret.map((v) =>
          merge(v, {_cellVariants: {deleted: v.deleted ? "danger" : null}})
        );
      },
      deparatament_list() {
        return [
          {uuid: null, name: this.$t("Without departament")},
          ...this.$store.getters.departament.map(d => ({uuid: d.uuid, name: d.deleted ? `${d.name} *` : d.name})),
        ];
      },
    },
    methods: {
      delete_departament() {
        this.$bvModal.msgBoxConfirm(
          this.$t('Delete departament')
        ).then(
          val => {
            if (val) {
              this.$store.dispatch(
                'update_item',
                {
                  type: 'departament',
                  id: this.deparatament_uuid,
                  data: {
                    deleted: new Date()
                  }
                }
              )
            }
          }
        )
      }
      ,
      delete_item(uuid, type = "additional_info") {
        const obj = this.items.find(o => o.uuid == uuid)
        this.$bvModal.msgBoxConfirm(
          this.$t('delete_msg', {name: obj.name})).then(val => {
          if (val) {
            this.$store.dispatch("update_item", {
              type: type,
              id: uuid,
              data: {
                deleted: new Date(),
              },
            });
          }
        })

      },
      show_edit(uuid, type = "additional_info") {
        const item = this.$store.getters[type].find((o) => o.uuid == uuid);
        if (item) {
          this.edit_modal = true;
          this.edit_uuid = uuid;
          this.action_type = type;
          this.edit_data.description = item.description;
          this.edit_data.name = item.name;
          if (type == 'additional_info') {
            this.edit_data.departament_uuid = item.departament_uuid;
          }
        }
      },
      update_info() {
        const type = this.action_type;
        this.$store
        .dispatch("update_item", {
          type: type,
          id: this.edit_uuid,
          data: this.edit_data,
        })
        .then(() => {
          this.edit_data.name = "";
          this.edit_data.description = "";
          this.edit_data.departament_uuid = undefined;
        });
      },
      show_create(type = "additional_info") {
        this.action_type = type;
        this.show_modal = true;
      },
      create_info() {
        const type = this.action_type;
        let add_info = {};
        if (type == "additional_info") {
          add_info["type"] = this.type;
          add_info["departament_uuid"] = this.deparatament_uuid;
        }
        this.$store
        .dispatch("add_item", {
          type: type,
          data: {
            ...this.new_data,
            ...add_info,
          },
        })
        .then((r) => {
          this.action_type = "additional_info";
          if (r) {
            if (type == "departament") {
              this.deparatament_uuid = r.uuid;
            }
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  .dep-filter {
    .form-row {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 5px;
    }
  }
</style>
